let key = {};

if (process.env.REACT_APP_MODE === 'production') {
  console.log('Running in Production Mode');
  key = {
    API_HOST: 'https://api.fxstgateway.com',
    API_URL: 'https://api.fxstgateway.com/api',
    getGeoInfo: 'https://ipapi.co/json/',
    SUMSUB_KYC_CHECK:
      'https://cockpit.sumsub.com/checkus#/applicants/individual?limit=10&page=0&searchQuery=',
    IMAGE: {
      LOGO: '/logo/',
      BUTTON: '/button-img/',
      CMS: '/cms/',
    },
  };
} else if (process.env.REACT_APP_MODE === 'demo') {
  console.log('Running in Demo Mode');
  key = {
    API_HOST: 'https://fxpaymentapi.wearedev.team',
    API_URL: 'https://fxpaymentapi.wearedev.team/api',
    getGeoInfo: 'https://ipapi.co/json/',
    SUMSUB_KYC_CHECK:
      'https://cockpit.sumsub.com/checkus#/applicants/individual?limit=10&page=0&searchQuery=',
    IMAGE: {
      LOGO: '/logo/',
      BUTTON: '/button-img/',
      CMS: '/cms/',
    },
  };
} else {
  console.log('Running in Development Mode');
  key = {
    API_HOST: 'http://localhost:5001',
    API_URL: 'http://localhost:5001/api',
    getGeoInfo: 'https://ipapi.co/json/',
    SUMSUB_KYC_CHECK:
      'https://cockpit.sumsub.com/checkus#/applicants/individual?limit=10&page=0&searchQuery=',
    IMAGE: {
      LOGO: '/logo/',
      BUTTON: '/button-img/',
      CMS: '/cms/',
    },
  };
}

export default key;
