import config from 'src/config';
import { Fragment, createContext, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const SockContext = createContext({ socket: null });
const SocketContextWrapper = ({ children }) => {
  const socket = io(config.API_HOST);
  const profile = useSelector((state) => state.profile);
  const useOnce = useRef(true);
  const router = useNavigate();

  useEffect(() => {
    if (useOnce.current) {
      console.log('RUN ONCE');
      useOnce.current = false;
      socket.on('connect', () => {
        socket.emit('CREATEROOM', profile.email);
      });
    }
  }, [profile.email, socket, router]);

  if (socket) {
    return <SockContext.Provider value={{ socket }}>{children}</SockContext.Provider>;
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default SocketContextWrapper;
