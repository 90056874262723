import './scss/style.scss';
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';
import ToastCAlert from './components/ToastCAlert';
import HelperRoute from './helper/HelperRoute';
import { getAuthToken, isLogin } from './lib/localStorage';
import { decodeJwt } from './lib/jsonWebToken';
import ConditionRoute from './helper/ConditionRoute';
import { useDispatch, useSelector } from 'react-redux';
import AutoLogout from './components/AutoLogout';
import SocketContextWrapper from './context/SocketContext';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const Login = React.lazy(() => import('./views/Login/Login'));

const App = () => {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!isAuth && isLogin()) {
      decodeJwt(getAuthToken(), dispatch);
    }
  }, [isAuth, dispatch]);

  return (
    <BrowserRouter>
      <SocketContextWrapper>
        <Suspense fallback={<Loading />}>
          <ToastCAlert />
          <HelperRoute />
          <Routes>
            <Route
              exact
              path="/login"
              name="Login"
              element={
                <ConditionRoute type="auth">
                  <Login />
                </ConditionRoute>
              }
            />
            <Route
              path="*"
              name="Home"
              element={
                <ConditionRoute type={'private'}>
                  <AutoLogout>
                    <DefaultLayout />
                  </AutoLogout>
                </ConditionRoute>
              }
            />
          </Routes>
        </Suspense>
      </SocketContextWrapper>
    </BrowserRouter>
  );
};

export default App;
